import * as api from '../services/api'

export const GET_PRESIGNED_UPLOAD_URL_ERROR = 'GET_PRESIGNED_UPLOAD_URL_ERROR'
export const GET_PRESIGNED_UPLOAD_URL_SUCCESS = 'GET_PRESIGNED_UPLOAD_URL_SUCCESS'
export const GET_PRESIGNED_UPLOAD_URL = 'GET_PRESIGNED_UPLOAD_URL'
export const GETTING_PRESIGNED_UPLOAD_URL = 'GETTING_PRESIGNED_UPLOAD_URL'
export const SUBMIT_UPLOAD = 'SUBMIT_UPLOAD'
export const SUBMIT_UPLOAD_SUCCESS = 'SUBMIT_UPLOAD_SUCCESS'
export const SUBMIT_UPLOAD_ERROR = 'SUBMIT_UPLOAD_ERROR'
export const SUBMITTING_UPLOAD = 'SUBMITTING_UPLOAD'

export const getPresignedUploadUrl = (file, cb) => {
  return (dispatch) => {
    const params = {
      objectName: file.name,
      contentType: file.type
    }

    dispatch(gettingPresignedUploadUrl(true))
    api.get('/public/presign_file', params)
      .then(result => {
        const { Data } = result
        if (Data) {
          const { Url } = Data
          dispatch(getPresignedUploadUrlSuccess(Data))
          cb({ signedUrl: Url })
        } else {
          dispatch(getPresignedUploadUrlError(result.Error))
        }
      }).catch((err) => dispatch(getPresignedUploadUrlError(err)))
  }
}

function gettingPresignedUploadUrl (getting) {
  return {
    type: GETTING_PRESIGNED_UPLOAD_URL,
    payload: getting
  }
}

function getPresignedUploadUrlSuccess (result) {
  return {
    type: GET_PRESIGNED_UPLOAD_URL_SUCCESS,
    payload: result
  }
}

function getPresignedUploadUrlError (err) {
  return {
    type: GET_PRESIGNED_UPLOAD_URL_ERROR,
    error: err
  }
}

export const submitUpload = (file) => {
  return (dispatch) => {
    dispatch(submittingUpload(true))
    api.post('/public/upload_file', file)
      .then(result => {
        const { Data } = result
        if (Data) {
          dispatch(submitUploadSuccess(Data))
        } else {
          dispatch(submitUploadError(result.Error))
        }
      }).catch((err) => dispatch(submitUploadError(err)))
  }
}

function submittingUpload (getting) {
  return {
    type: SUBMITTING_UPLOAD,
    payload: getting
  }
}

function submitUploadSuccess (result) {
  return {
    type: SUBMIT_UPLOAD_SUCCESS,
    payload: result
  }
}

function submitUploadError (err) {
  return {
    type: SUBMIT_UPLOAD_ERROR,
    error: err
  }
}
