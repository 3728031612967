import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import ProviderSearchBar from './ProviderSearchBar'
import ProviderSearchResults from './ProviderSearchResults'
import { Container, Header, Divider } from 'semantic-ui-react'

export default class ProviderSearch extends Component {
  render () {
    // Adjust class if this is being viewed in an iframe on the main site
    const containerClassName = this.props.params.source === 'main-website' ? 'provider-search-container-from-main-site' : 'provider-search-container'

    return (<div>
      <Container className={containerClassName}>
        <div>
          <div><Header size='huge'>Find Care</Header></div>
          <div className='ps-back-to-home'><Link to={`/?${queryString.stringify({source: this.props.params.source})}`}>Back to Search</Link></div>
          <ProviderSearchBar params={this.props.params} search={this.props.search} clearLocation={this.props.clearLocation} curLocation={this.props.curLocation} getLocation={this.props.getLocation} history={this.props.history} />
        </div>
        <Divider />
        <ProviderSearchResults params={this.props.params} searching={this.props.searching} hovered={this.props.hovered} onHover={this.props.onHover} error={this.props.error} results={this.props.results} />
      </Container>
    </div>)
  }
}

ProviderSearch.propTypes = {
  search: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  clearLocation: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
  curLocation: PropTypes.object.isRequired,
  error: PropTypes.bool,
  results: PropTypes.array,
  hovered: PropTypes.number,
  params: PropTypes.object,
  history: PropTypes.object
}
