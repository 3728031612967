import { CLEAR_SEARCH_RESULTS, SEARCHING_FOR_PROVIDERS, SEARCH_FOR_PROVIDER_SUCCESS, SEARCH_FOR_PROVIDER_ERROR, FINDING_NEARBY_PROVIDERS, FIND_NEARBY_PROVIDERS_SUCCESS, FIND_NEARBY_PROVIDERS_ERROR, CLEAR_LISTING } from '../actions/ProviderActions'

export default function (state = null, action) {
  switch (action.type) {
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        results: []
      }
    case CLEAR_LISTING:
      return {
        ...state,
        listing: []
      }
    case FINDING_NEARBY_PROVIDERS:
      return {
        listing: state.listing,
        isSearching: action.isSearching
      }
    case FIND_NEARBY_PROVIDERS_ERROR:
      return {
        error: true,
        results: []
      }
    case FIND_NEARBY_PROVIDERS_SUCCESS:
      return {
        listing: action.payload
      }
    case SEARCHING_FOR_PROVIDERS:
      return {
        results: state.results,
        isSearching: action.isSearching
      }
    case SEARCH_FOR_PROVIDER_ERROR:
      return {
        error: true,
        results: []
      }
    case SEARCH_FOR_PROVIDER_SUCCESS:
      return {
        results: action.payload
      }
    default:
      return {
        ...state
      }
  }
}
