export const MAP_ITEM_HOVERED = 'MAP_ITEM_HOVERED'

function mapItemHovered (index) {
  return {
    type: MAP_ITEM_HOVERED,
    payload: index
  }
}

export { mapItemHovered }
