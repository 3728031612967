import React from 'react'
import PropTypes from 'prop-types'
import BaseComponent from '../BaseComponent'
import queryString from 'query-string'
import { ZipcodeInput } from '../../components/Inputs/Zipcode'
import { Form, Icon, Button } from 'semantic-ui-react'

export default class Search extends BaseComponent {
  constructor (props) {
    super(props)

    this.zipRef = React.createRef()

    this.state = {
      distanceOptions: [
        { key: '25mi', text: '25 mi', value: '25mi' },
        { key: '50mi', text: '50 mi', value: '50mi' },
        { key: '100mi', text: '100 mi', value: '100mi' },
        { key: '300mi', text: '300 mi', value: '300mi' }
      ],
      fields: {
        distance: props.params.distance || '100mi',
        zipcode: props.params.zipcode || ''
      }
    }
  }

  componentWillMount () {
    if (this.props.autoExec) {
      this.checkToAutoExecSearch()
    }
  }

  checkToAutoExecSearch () {
    if (this.ableToSearch()) {
      this.executeSearch()
      return true
    }

    return false
  }

  ableToSearch () {
    const { fields } = this.state
    const { zipcode } = fields

    if (this.isValidZipcode(zipcode)) {
      return true
    }

    return false
  }

  isValidZipcode (zipcode) {
    if (zipcode && zipcode.length === 5) return true
    return false
  }

  handleZipChange = (val) => {
    this.setState({
      fields: {
        ...this.state.fields,
        zipcode: val
      }
    })
  }

  handleDistanceChange = (e, data) => {
    this.handleSelectChange(e, data)
  }

  handleFindNearby = () => {
    if (this.checkToAutoExecSearch()) {
      this.refs.findNearbyBtn.focus() // this makes sure we clear focus on inputs when a search is exec.
      return
    }

    this.handleIncompleteSearch()
  }

  handleIncompleteSearch () {
    const { zipcode } = this.state.fields

    if (!this.isValidZipcode(zipcode)) {
      this.zipRef.current.focus()
    }
  }

  executeSearch = () => {
    const { fields } = this.state
    const { distance, zipcode } = fields
    const qsParams = {  ...this.props.params , distance, zipcode}

    this.props.history.push(`/providers?${queryString.stringify(qsParams)}`)
    this.props.findNearby({ distance, zipcode })
  }

  render () {
    return (<div>
      <Form size='large' style={{fontFamily: 'Raleway !important'}} >
        <Form.Group>
          <Form.Select style={{fontFamily: 'Raleway'}}  className='searchbar-field distance' name='distance' label='Within Distance' value={this.state.fields.distance} onChange={this.handleDistanceChange} options={this.state.distanceOptions} />
          <ZipcodeInput style={{fontFamily: 'Raleway'}}  loading={this.props.loading} name='zipcode' value={this.state.fields.zipcode} onChange={this.handleZipChange} focusRef={this.zipRef} />
          <Form.Field style={{fontFamily: 'Raleway'}}  className='searchbar-btn'>
            <Button style={{fontFamily: 'Raleway'}}  size='large' onClick={this.handleFindNearby} type='submit' ref='findNearbyBtn'><Icon name='search' />Find Nearby Providers</Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </div>)
  }
}

Search.defaultProps = {
  findNearby: () => { console.log('Must set findNearby in order for search to work') },
  loading: false,
  autoExec: true,
  params: {}
}

Search.propTypes = {
  findNearby: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  autoExec: PropTypes.bool
}
