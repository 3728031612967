export const SET_USER = 'SET_USER'

function setUser (user) {
  return {
    type: SET_USER,
    user
  }
}

export { setUser }
