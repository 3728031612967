import React, { Component } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryWrapper } from './ErrorBoundary'
import './App.css'

class App extends Component {
  render () {
    return (
      <ErrorBoundary FallbackComponent={ErrorBoundaryWrapper}>
        <div className='App'>
          <h1 className='App-title'>Zero</h1>
        </div>
      </ErrorBoundary>
    )
  }
}

export default App
