import { REQUEST_BROWSER_LOCATION_SUCCESS, REQUEST_BROWSER_LOCATION_ERROR, REQUESTING_BROWSER_LOCATION, CLEAR_LOCATION } from '../actions/LocationActions'

export default function (state = null, action) {
  switch (action.type) {
    case REQUEST_BROWSER_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.location,
        isLoading: false
      }
    case REQUEST_BROWSER_LOCATION_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false
      }
    case REQUESTING_BROWSER_LOCATION:
      return {
        ...state,
        isLoading: action.isLoading
      }
    case CLEAR_LOCATION: {
      return {
        ...state,
        location: {
          lat: 0,
          lon: 0
        }
      }
    }
    default:
      return {
        ...state
      }
  }
}
