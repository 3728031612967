import { MAP_ITEM_HOVERED } from '../actions/MapActions'

export default function (state = null, action) {
  switch (action.type) {
    case MAP_ITEM_HOVERED:
      return {
        ...state,
        hoveredId: action.payload.hovered
      }
    default:
      return {
        ...state
      }
  }
}
