import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import NearbyProvidersSearchBar from './NearbyProvidersSearchBar'
import queryString from 'query-string'
import { Icon, Header, Dimmer, Divider, Loader, Container, Grid, } from 'semantic-ui-react'
import { generateGoogleMapsLink, phoneFormatter } from '../../helpers'
import './ProviderListing.scss'
const ZC_PHONE = '8558160001'

class ProviderListingResult extends Component {
  renderSpecialities = (facility) => {
    if (facility && facility.Specializations && facility.Specializations.length > 0) {
      return facility.Specializations.map(spec => {
        return (<div>{spec.Label}</div>)
      })
    }
    return 'N/A'
  }

  render () {
    const { result } = this.props
    const phoneNumber = result.Fastpass ? result.Phone : ZC_PHONE
    const phoneLink = `tel:${phoneNumber}`
    const googleLink = generateGoogleMapsLink(result.Address.DisplayAddress)
    const phoneSteerage = result.Fastpass ? `call directly to inquire!` : `call ZERO to schedule or inquire!`
    const openChat = () => window.parent.postMessage('openChat', '*')

    return (<div className='pl-result'>
      <Header size='medium'>{result.Name}</Header>
      <Grid columns={3} divided>
        <Grid.Row>
          <Grid.Column>
            <div><Header size='small'>Location</Header></div>
            <div className='ps-details-distance'><Icon name='point' />{result.Distance.toFixed(1)} miles</div>
            <div>{result.Address.DisplayAddress}</div>
            <div><a href={googleLink} target='_blank' rel='noopener noreferrer'><Icon name='external' /> Open in Google Maps</a></div>
          </Grid.Column>
          <Grid.Column>
            <div><Header size='small'>Contact</Header></div>
            <div><a onClick={openChat} style={{ cursor: 'pointer' }}>Chat</a> or {phoneSteerage}</div>
            {result.Fastpass ? <div>Must Present your Quest/ZERO Member ID Card</div> : null}
            <div><a href={phoneLink}>{phoneFormatter(phoneNumber)}</a></div>
          </Grid.Column>
          <Grid.Column>
            <div><Header size='small'>Specialities</Header></div>
            <div>{this.renderSpecialities(result)}</div>
          </Grid.Column>
        </Grid.Row>
    </Grid>
    </div>)
  }
}

class ProviderListingResults extends Component {
  renderNoResults (text) {
    return (<Container fluid text textAlign='left' className='ps-no-results'>{text}</Container>)
  }

  renderResults () {
    const { zipcode } = this.props.params
    if (this.props.error) return this.renderNoResults('There was an error during your search. Please try again or contact us here for support: support@zero.health')
    if (this.props.results) {
      if (this.props.results.length > 0) {
        return (<div>
            <div className="pl-num-results"><Header size='large'>{this.props.results.length} locations found near {zipcode}</Header></div>
            {this.props.results.map(result => {
            return (<ProviderListingResult id={result.ID} key={result.ID} result={result} />)
          })}
          </div>)
      } else {
        return this.renderNoResults('No providers found in your area. Try adjusting the distance or zip code and try again.')
      }
    }

    return this.renderNoResults('Find providers near you.')
  }

  render () {
    return (<div>
      <Container className='provider-search-results-container'>
        {this.props.searching ? <Dimmer active inverted className='ps-loader'><Loader /></Dimmer> : this.renderResults()}
      </Container>
    </div>)
  }
}

ProviderListingResults.defaultProps = {
  params: {}
}

ProviderListingResults.propTypes = {
  searching: PropTypes.bool,
  error: PropTypes.bool,
  results: PropTypes.array,
  params: PropTypes.object
}


export default class ProviderListing extends Component {
  render () {
    // Adjust class if this is being viewed in an iframe on the main site
    const containerClassName = this.props.params.source === 'main-website' ? 'provider-search-container-from-main-site' : 'provider-search-container'

    return (<Container className={containerClassName}>
      <div>
        <div><Header size='huge'>Nearby Providers</Header></div>
        <div className='pl-back-to-home'><Link to={`/?${queryString.stringify({source: this.props.params.source})}`}>Back to Search</Link></div>
        <NearbyProvidersSearchBar loading={this.props.searching} findNearby={this.props.findNearby} history={this.props.history} params={this.props.params} />
      </div>
      <Divider />
      <ProviderListingResults searching={this.props.searching} error={this.props.error} results={this.props.results} params={this.props.params} />
    </Container>)
  }
}

ProviderListing.propTypes = {
  onHover: PropTypes.func.isRequired,
  params: PropTypes.object,
  results: PropTypes.array,
  findNearby: PropTypes.func.isRequired,
  hovered: PropTypes.number,
  searching: PropTypes.bool,
  error: PropTypes.bool
}
