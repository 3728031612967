import * as api from '../services/api'

export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'
export const SEARCH_FOR_PROVIDER = 'SEARCH_FOR_PROVIDER'
export const SEARCH_FOR_PROVIDER_SUCCESS = 'SEARCH_FOR_PROVIDER_SUCCESS'
export const SEARCH_FOR_PROVIDER_ERROR = 'SEARCH_FOR_PROVIDER_ERROR'
export const SEARCHING_FOR_PROVIDERS = 'SEARCHING_FOR_PROVIDERS'
export const CLEAR_LISTING = 'CLEAR_LISTING'
export const FIND_NEARBY_PROVIDERS = 'FIND_NEARBY_PROVIDERS'
export const FINDING_NEARBY_PROVIDERS = 'FINDING_NEARBY_PROVIDERS'
export const FIND_NEARBY_PROVIDERS_SUCCESS = 'FIND_NEARBY_PROVIDERS_SUCCESS'
export const FIND_NEARBY_PROVIDERS_ERROR = 'FIND_NEARBY_PROVIDERS_ERROR'


function searchForProvider (params) {
  return (dispatch) => {
    dispatch(searchingForProviders(true))
    api.get('/public/provider_search', params)
      .then((results) => {
        if (results.error) {
          dispatch(searchForProviderError(results.Error))
        } else {
          dispatch(searchForProviderSuccess(results.Data))
        }
      })
      .catch((err) => dispatch(searchForProviderError(err)))
  }
}

function searchForProviderSuccess (results) {
  return {
    type: SEARCH_FOR_PROVIDER_SUCCESS,
    payload: results
  }
}

function searchForProviderError (err) {
  return {
    type: SEARCH_FOR_PROVIDER_ERROR,
    error: err
  }
}

function searchingForProviders (searching) {
  return {
    type: SEARCHING_FOR_PROVIDERS,
    isSearching: searching
  }
}

function findNearbyProviders (params) {
  return (dispatch) => {
    dispatch(findingNearbyProviders(true))
    api.get('/public/provider_listing', params)
      .then((results) => {
        if (results.error) {
          dispatch(findNearbyProvidersError(results.Error))
        } else {
          dispatch(findNearbyProvidersSuccess(results.Data))
        }
      })
      .catch((err) => dispatch(findNearbyProvidersError(err)))
  }
}

function findNearbyProvidersSuccess (results) {
  return {
    type: FIND_NEARBY_PROVIDERS_SUCCESS,
    payload: results
  }
}

function findNearbyProvidersError (err) {
  return {
    type: FIND_NEARBY_PROVIDERS_ERROR,
    error: err
  }
}

function findingNearbyProviders (finding) {
  return {
    type: FINDING_NEARBY_PROVIDERS,
    isSearching: finding
  }
}

function clearListing () {
  return {
    type: CLEAR_SEARCH_RESULTS
  }
}

function clearSearchResults () {
  return {
    type: CLEAR_SEARCH_RESULTS
  }
}

export {
  clearListing,
  searchForProvider,
  clearSearchResults,
  findNearbyProviders
}
