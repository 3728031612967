import React from 'react'

/**
 * Fall back component for the main error boundary.
 * This can be used for routing errors to reporting services in the future.
 */
export const ErrorBoundaryWrapper = ({ error, resetErrorBoundary }) => {
  let message = 'Something went wrong. Please contact support@zero.health if the issue persists'

  // Display a custom message based on the error that a permissions error is caught
  if (error && error.message === '403') {
    message = 'You do not have access to this page. Please talk to your administrator to get the correct permissions.'
  }

  return (
    <div style={{ textAlign: 'center'}}>
      <h3>{message}</h3>
    </div>)
}
