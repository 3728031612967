import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { getPresignedUploadUrl, submitUpload } from '../actions'
import compose from 'recompose/compose'
import Inbox from '../components/Inbox'

class InboxContainer extends Component {
  constructor (props) {
    super(props)

    const { location } = props
    const params = queryString.parse(location.search)

    this.state = {
      params: {
        ...params
      }
    }
  }

  render () {
    return <Inbox getPresignedUploadUrl={this.props.getPresignedUploadUrl} submitUpload={this.props.submitUpload} error={this.props.error} presignResult={this.props.result} uploading={this.props.uploading} uploadResult={this.props.uploadResult} />
  }
}

function mapStateToProps (state) {
  return {
    uploading: state.inbox.uploading,
    error: state.inbox.error,
    result: state.inbox.result,
    uploadResult: state.inbox.uploadResult
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ getPresignedUploadUrl, submitUpload }, dispatch)
}

InboxContainer.propTypes = {
  uploading: PropTypes.bool,
  error: PropTypes.bool,
  result: PropTypes.object,
  uploadResult: PropTypes.object
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(InboxContainer)
