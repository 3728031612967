import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { searchForProvider, clearSearchResults } from '../actions/ProviderActions'
import { mapItemHovered } from '../actions/MapActions'
import { requestBrowserLocation, clearLocation } from '../actions/LocationActions'
import compose from 'recompose/compose'

import ProviderSearch from '../components/ProviderSearch/ProviderSearch'

class ProviderSearchContainer extends Component {
  constructor (props) {
    super(props)
    const { location } = props
    const params = queryString.parse(location.search)

    this.state = {
      params: {
        ...params
      }
    }
  }

  render () {
    return (<ProviderSearch searching={this.props.searching} hovered={this.props.hovered} onHover={this.props.mapItemHovered} getLocation={this.props.requestBrowserLocation} search={this.props.searchForProvider} results={this.props.results} error={this.props.error} curLocation={this.props.curLocation} clearLocation={this.props.clearLocation} params={this.state.params} history={this.props.history} />)
  }
}

function mapStateToProps (state) {
  return {
    error: state.providers.error,
    results: state.providers.results,
    searching: state.providers.isSearching || false,
    curLocation: state.location || {},
    hovered: state.map.hoveredId
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ requestBrowserLocation, clearLocation, searchForProvider, clearSearchResults, mapItemHovered }, dispatch)
}

ProviderSearchContainer.propTypes = {
  error: PropTypes.bool,
  results: PropTypes.array,
  searching: PropTypes.bool.isRequired,
  curLocation: PropTypes.object.isRequired,
  hovered: PropTypes.number,
  history: PropTypes.object.isRequired
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProviderSearchContainer)
