import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Message, Form, TextArea, Container, Header, Button, Input, Dropdown, Card } from 'semantic-ui-react'
import { FileUploader } from '../FileUploader'
import { Captcha } from '../Captcha'
import './Inbox.css'

export default class Inbox extends Component {
  state = {
    fields: {
      FromFirstName: '',
      FromLastName: '',
      FromEmployerName: '',
      Email: '',
      Notes: '',
      Type: 'claims',
      S3Key: '',
      Recipient: '',
      Captcha: '',
      FileName: ''
    },
    dirty: {},
    fileTypeOptions: [
      { text: 'Claims', value: 'claims' },
      { text: 'Other', value: 'other' }
    ],
    uploaded: false,
    uploading: false,
    uploadError: null
  }

  handleSelectChange = (name) => {
    return (e, { value }) => {
      const { fields } = this.state
      this.setState({
        fields: {
          ...fields,
          [name]: value
        }
      })
    }
  }

  handleChange = (name) => {
    return (e) => {
      const { fields } = this.state
      this.setState({
        fields: {
          ...fields,
          [name]: e.target.value
        }
      })
    }
  }

  handleBlur = (name) => {
    return () => {
      const { dirty } = this.state

      this.setState({
        dirty: {
          ...dirty,
          [name]: true
        }
      })
    }
  }

  handleSubmit = (e) => {
    const { fields } = this.state
    return this.props.submitUpload({ ...fields })
  }

  onUploadStart = () => {
    this.setState({
      uploading: true,
      uploadError: null
    })
  }

  onUploadComplete = () => {
    this.setState({
      uploading: false,
      uploadError: null
    })
  }

  onUploadError = (err) => {
    this.setState({
      uploading: false,
      uploadError: err && err.Error && err.Error.Message
    })
  }

  componentWillReceiveProps = (nextProps) => {
    const { presignResult, uploadResult, error } = nextProps
    const { fields } = this.state
    if (uploadResult) {
      this.setState({
        uploaded: true
      })
    }
    if (presignResult) {
      const { S3Key } = presignResult
      this.setState({
        fields: {
          ...fields,
          S3Key: S3Key
        }
      })
    }
    if (error === true) {
      this.setState({
        error: true,
        fields: {
          ...fields,
          S3Key: ''
        }
      })
    }
    if (error === false) {
      this.setState({
        error: false
      })
    }
  }

  handleCaptchaVerified = (token) => {
    const { fields } = this.state
    this.setState({
      fields: {
        ...fields,
        Captcha: token
      }
    })
  }

  isEmailValid = () => {
    const { Email } = this.state.fields
    return Email && Email.indexOf('@') !== -1 && Email.length >= 3
  }

  isNotesValid = () => {
    const { Notes } = this.state.fields
    return Notes && Notes.length > 0
  }

  isValid = () => {
    const { uploading, uploadError } = this.state
    const { Captcha, Type, S3Key } = this.state.fields
    const uploadFileValid = !uploading && uploadError === null

    const valid = !!(this.isFirstNameValid() &&
      this.isLastNameValid() &&
      this.isEmailValid() &&
      this.isRecipientValid() &&
      this.isNotesValid() &&
      (Type && Type !== '') &&
      (S3Key && S3Key !== '') &&
      uploadFileValid &&
      (Captcha && Captcha !== '')
    )
    return valid
  }

  isFirstNameValid = () => {
    const { FromFirstName } = this.state.fields

    return (FromFirstName && FromFirstName.length > 1)
  }

  isLastNameValid = () => {
    const { FromLastName } = this.state.fields

    return (FromLastName && FromLastName.length > 1)
  }

  isRecipientValid = () => {
    const { Recipient } = this.state.fields

    return (Recipient && Recipient.length > 1)
  }

  onFileSelected = (f) => {
    const { fields } = this.state
    this.setState({
      fields: {
        ...fields,
        FileName: f.name
      }
    })
  }

  renderError = () => {
    const { uploadError } = this.state
    return (<div>
      <Message negative>
        <Message.Header>We are sorry, there was an issue uploading your file. Please contact support@zero.health</Message.Header>
        <Message.Content>{ uploadError ? `Take a screenshot of this error message for your email: ${uploadError}` : `` }</Message.Content>
      </Message>
    </div>)
  }

  renderUploadForm = () => {
    const { fileTypeOptions, fields, dirty } = this.state
    const { Type, Email, Notes, FromFirstName, FromLastName, Recipient, FromEmployerName } = fields

    return (<Form>
      <Card className='internal-card'>
        <Card.Content>
          <Card.Header>1. Fill out your contact information</Card.Header>
          <Card.Description>
            <ValidatedInput placeholder='First Name' className='field' onChange={this.handleChange('FromFirstName')} validationMsg='Must be 2 or more letters.' value={FromFirstName} valid={this.isFirstNameValid()} dirty={dirty.FromFirstName} onBlur={this.handleBlur('FromFirstName')} />
            <ValidatedInput placeholder='Last Name' className='field' onChange={this.handleChange('FromLastName')} validationMsg='Must be 2 or more letters.' value={FromLastName} valid={this.isLastNameValid()} dirty={dirty.FromLastName} onBlur={this.handleBlur('FromLastName')} />
            <ValidatedInput placeholder='Email Address' className='field' onChange={this.handleChange('Email')} validationMsg='Must be a valid email (have @ symbol).' value={Email} valid={this.isEmailValid()} dirty={dirty.Email} onBlur={this.handleBlur('Email')} />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card className='internal-card'>
        <Card.Content>
          <Card.Header>2. Select a file to upload</Card.Header>
          <Card.Description>
          <Header size='tiny' className='file-type'>Type of File</Header>
            <Dropdown selection placeholder='Type of File' options={fileTypeOptions} className='field' onChange={this.handleSelectChange('Type')} value={Type} />
            <ValidatedTextArea placeholder='Notes' className='field' onChange={this.handleChange('Notes')} validationMsg={'Please enter some notes'} value={Notes} valid={this.isNotesValid()} dirty={dirty.Notes} onBlur={this.handleBlur('Notes')} />
            <ValidatedInput placeholder='Recipient Name or Email Address' className='field' onChange={this.handleChange('Recipient')} validationMsg='Must be 2 or more letters.' value={Recipient} valid={this.isRecipientValid()} dirty={dirty.Recipient} onBlur={this.handleBlur('Recipient')} />
            <div style={{textAlign:'center',fontSize:'90%',background:'#ffc491',padding:'0.5rem',borderRadius:'5px',lineHeight:'1',fontWeight:'bold',margin:'0.5rem 0'}}>Please upload eligibility files via <a href="https://team.zero.health" target="_blank">teamZERO</a></div>
            <FileUploader getPresignedUploadUrl={this.props.getPresignedUploadUrl} error={this.props.fileUploadError} result={this.props.fileUploadResult} onUploadStart={this.onUploadStart} onUploadError={this.onUploadError} onUploadComplete={this.onUploadComplete} onFileSelected={this.onFileSelected} />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card className='internal-card'>
        <Card.Content>
          <Card.Header>3. Company Name</Card.Header>
          <p>Which company or employer is this file relevant to?</p>
          <Card.Description>
            <Input placeholder='Company/Employer Name' className='field' onChange={this.handleChange('FromEmployerName')} value={FromEmployerName} />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card className='internal-card'>
        <Card.Content>
          <Card.Header>4. Confirm and submit</Card.Header>
          <Card.Description>
            <Captcha onUserVerified={this.handleCaptchaVerified} />
            <Button onClick={this.handleSubmit} disabled={!this.isValid()}>Submit</Button>
            <div className='ui form note-text'>Note: All fields except <i>Company/Employer Name</i> must be filled out and your file must be fully uploaded (100%) before you will be able to Submit this form.</div>
          </Card.Description>
        </Card.Content>
      </Card>
    </Form>)
  }

  renderSuccess = () => {
    return (<div>
      <Card className='internal-card'>
        <Card.Content>
          <Card.Header>Your file has been submitted securely and successfully!</Card.Header>
          <Card.Description>
            <p>
              A confirmation email has been sent to{` `}
              {this.state.fields.Email ? this.state.fields.Email : 'your email address'}
            </p>
            <Button onClick={() => window.location.reload()}>Submit another file</Button>
          </Card.Description>
        </Card.Content>
      </Card>
    </div>)
  }

  render () {
    const { uploaded, error } = this.state
    return (<div>
      <Container textAlign='center' className='content-container'>
        <div>
          <img alt='logo' height='50px' src='https://tzc-prd-assets.s3.us-west-2.amazonaws.com/images/Zero-MasterLogo-dark.png' />
          <Header size='huge'>Secure Inbox</Header>
        </div>
        <Header size='small' className='margined'>Use this form to securely upload files for Zero</Header>
        <Container textAlign='center' className='internal-container'>
          { uploaded ? this.renderSuccess() : this.renderUploadForm() }
          { error && this.renderError() }
        </Container>
      </Container>
    </div>)
  }
}

Inbox.propTypes = {
  error: PropTypes.bool,
  result: PropTypes.array,
  getPresignedUploadUrl: PropTypes.func.isRequired
}

class ValidatedInput extends React.Component {
  render () {
    const { valid, dirty } = this.props
    const errored = (!valid && dirty)
    let icon = ''
    if (errored) icon = 'warning circle'
    if (valid) icon = 'check'
    let className = `ui input field`

    if (errored) className = `${className} dirty`
    return (<div>
      <Input placeholder={this.props.placeholder} className={className} onChange={this.props.onChange} value={this.props.value} onBlur={this.props.onBlur} icon={icon} error={errored} />
      <span className='validation-msg'>{errored && this.props.validationMsg}</span>
    </div>)
  }
}

class ValidatedTextArea extends React.Component {
  render () {
    const { valid, dirty } = this.props
    const errored = (!valid && dirty)
    let className = `ui field`

    if (errored) className = `${className} dirty`
    return (<div>
      <TextArea placeholder={this.props.placeholder} className={className} onChange={this.props.onChange} onBlur={this.props.onBlur} value={this.props.value} />
      <span className='validation-msg'>{errored && this.props.validationMsg}</span>
    </div>)
  }
}
