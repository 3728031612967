import React, { Component } from 'react'
import { Form, Input } from 'semantic-ui-react'

export default class BaseComponent extends Component {
  constructor (props) {
    super(props)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.renderInput = this.renderInput.bind(this)
  }

  handleSelectChange (e, data) {
    this.setState({ fields: {
      ...this.state.fields,
      [data.name]: data.value
    }})
  }

  handleInputChange (e) {
    this.setState({ fields: {
      ...this.state.fields,
      [e.target.name]: e.target.value
    }})
  }

  renderInput (name, label, placeholder, opts) {
    return (<Form.Field className='searchbar-field'>
      <label>{label}</label>
      <Input name={name} ref={name} placeholder={placeholder} value={this.state.fields[name]} onChange={this.handleInputChange} {...opts} />
    </Form.Field>)
  }
}
