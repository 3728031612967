export const REQUEST_BROWSER_LOCATION = 'REQUEST_BROWSER_LOCATION'
export const REQUEST_BROWSER_LOCATION_SUCCESS = 'REQUEST_BROWSER_LOCATION_SUCCESS'
export const REQUEST_BROWSER_LOCATION_ERROR = 'REQUEST_BROWSER_LOCATION_ERROR'
export const REQUESTING_BROWSER_LOCATION = 'REQUESTING_BROWSER_LOCATION'
export const CLEAR_LOCATION = 'CLEAR_LOCATION'

function requestBrowserLocation () {
  return (dispatch) => {
    dispatch(requestingBrowserlocation(true))
    getCurrentPosition()
      .then(result => dispatch(requestBrowserLocationSuccess(result)))
      .catch(err => dispatch(requestBrowserLocationError(err)))
  }
}

function requestBrowserLocationSuccess (results) {
  return {
    type: REQUEST_BROWSER_LOCATION_SUCCESS,
    location: results
  }
}

function requestBrowserLocationError (err) {
  return {
    type: REQUEST_BROWSER_LOCATION_ERROR,
    error: err
  }
}

function requestingBrowserlocation (bool) {
  return {
    type: REQUESTING_BROWSER_LOCATION,
    isLoading: bool
  }
}

function clearLocation () {
  return {
    type: CLEAR_LOCATION
  }
}

function getCurrentPosition () {
  if (navigator && navigator.geolocation) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(res => {
        resolve({ lat: res.coords.latitude, lon: res.coords.longitude })
      }, err => reject(err), { timeout: 7000 })
    })
  }

  return null
}

export {
  requestBrowserLocation,
  clearLocation
}
