import { combineReducers } from 'redux'
import providers from './reducer_provider_search'
import location from './reducer_location'
import user from './reducer_user'
import map from './reducer_map'

const rootReducer = combineReducers({
  providers,
  location,
  user,
  map
})

export default rootReducer
