import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { findNearbyProviders, clearListing } from '../actions/ProviderActions'
import compose from 'recompose/compose'

import ProviderListing from '../components/ProviderListing/ProviderListing'

class ProviderListingContainer extends Component {
  constructor (props) {
    super(props)
    const { location } = props
    const params = queryString.parse(location.search)
    this.state = {
      params: {
        ...params
      }
    }
  }

  componentWillReceiveProps (nextProps) {
    const { location } = nextProps
    const params = queryString.parse(location.search)

    this.setState({
      params: {
        ...params
      }
    })
  }
  render () {
    return (<ProviderListing searching={this.props.searching} results={this.props.results} findNearby={this.props.findNearbyProviders} error={this.props.error} params={this.state.params} history={this.props.history} />)
  }
}

function mapStateToProps (state) {
  return {
    error: state.providers.error,
    results: state.providers.listing,
    searching: state.providers.isSearching || false
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ findNearbyProviders, clearListing }, dispatch)
}

ProviderListingContainer.propTypes = {
  error: PropTypes.bool,
  results: PropTypes.array,
  searching: PropTypes.bool.isRequired
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProviderListingContainer)
