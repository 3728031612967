import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import SearchApp from './Search/SearchApp'
import Inbox from './Inbox/Inbox'

// import registerServiceWorker from './registerServiceWorker'
const renderTarget = process.env.REACT_APP_RENDER_COMPONENT
const PROVIDER_SEARCH = 'provider_search'
const INBOX = 'inbox'

switch (renderTarget) {
  case PROVIDER_SEARCH:
    ReactDOM.render(<SearchApp />, document.getElementById('root'))
    break
  case INBOX:
    ReactDOM.render(<Inbox />, document.getElementById('root'))
    break
  default:
    console.error('Unknown render component specified as REACT_APP_RENDER_COMPONENT in the .env')
    ReactDOM.render(<App />, document.getElementById('root'))
    break
}

// registerServiceWorker()
