import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ProviderSearchContainer from './containers/ProviderSearchContainer'
import ProviderListingContainer from './containers/ProviderListingContainer'
import LandingContainer from './containers/LandingContainer'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryWrapper } from '../ErrorBoundary'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import reducers from './reducers'

const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore)

export default class SearchApp extends Component {
  render () {
    return (
      <ErrorBoundary FallbackComponent={ErrorBoundaryWrapper}>
        <Provider store={createStoreWithMiddleware(reducers)}>
          <Router>
            <Switch>
              <Route exact path='/' render={(props) => <LandingContainer {...props} />} />
              <Route exact path='/search' render={(props) => <ProviderSearchContainer {...props} />} />
              <Route exact path='/providers' render={(props) => <ProviderListingContainer {...props} />} />
            </Switch>
          </Router>
        </Provider>
      </ErrorBoundary>
    )
  }
}
