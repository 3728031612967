import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { requestBrowserLocation, clearLocation } from '../actions/LocationActions'
import { findNearbyProviders, clearSearchResults } from '../actions/ProviderActions'
import compose from 'recompose/compose'

import Landing from '../components/Landing'

// the search here is a noop, because when we load the provider search component it will want to
// handle the search for us.
const noop = () => {}

class LandingContainer extends Component {
  constructor (props) {
    super(props)
    const { location } = props
    const params = queryString.parse(location.search)

    this.state = { params }
  }

  render () {
    return (<Landing searching={this.props.searching} findNearby={this.props.findNearbyProviders} search={noop} loading={this.props.loading} error={this.props.error} params={this.state.params} history={this.props.history} curLocation={this.props.curLocation} clearLocation={this.props.clearLocation} getLocation={this.props.requestBrowserLocation} />)
  }
}

function mapStateToProps (state) {
  return {
    error: state.providers.error,
    searching: state.providers.isLandinging || false,
    curLocation: state.location || {}
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ requestBrowserLocation, clearLocation, findNearbyProviders, clearSearchResults }, dispatch)
}

LandingContainer.propTypes = {
  error: PropTypes.bool,
  findNearbyProviders: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LandingContainer)
