import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, Header, Dimmer, Loader, Container, Grid } from 'semantic-ui-react'
import MapResultsContainer from '../Map/MapResultsContainer'
import { generateGoogleMapsLink, phoneFormatter } from '../../helpers'
const ZC_PHONE = '8558160001'

class ProviderSearchResult extends Component {
  constructor (props) {
    super(props)

    this.handleHover = this.handleHover.bind(this)
    this.handleOffHover = this.handleOffHover.bind(this)
  }

  handleHover (e, data) {
    this.props.onHover({ hovered: this.props.id })
  }

  handleOffHover () {
    this.props.onHover({ hovered: -1 })
  }

  render () {
    const { result, hovered } = this.props
    const phoneNumber = result.Fastpass ? result.Phone : ZC_PHONE
    let procedure = ''
    let numProcedures = 0
    if (result.Prices && result.Prices.length > 0) {
      procedure = result.Prices[0].GroupDescription || result.Prices[0].LaymanDescription || result.Prices[0].Description
      numProcedures = result.Prices.length
    }
    const numRelatedProcedures = numProcedures - 1
    const hover = hovered && 'hovered'
    const internalClassName = `${hover} ps-result-internal`
    const procedureText = ` and ${numRelatedProcedures} similar procedure${numRelatedProcedures > 1 ? 's' : ''}`
    const phoneLink = `tel:${phoneNumber}`
    const googleLink = generateGoogleMapsLink(result.Address.DisplayAddress)
    const distance = <div className='ps-details-distance'><Icon name='point' />{result.Distance.toFixed(1)} miles</div>
    const phoneSteerage = result.Fastpass ? `call ${result.Name} directly to inquire!` : `call ZERO to schedule or inquire!`
    const openChat = () => window.parent.postMessage('openChat', '*')

    return (<div className='ps-result'>
      <div className={internalClassName} onMouseOver={this.handleHover} onMouseLeave={this.handleOffHover}>
        {procedure && <div className='ps-procedure-block'><Header sub size='medium'>{procedure}</Header><i>{numProcedures > 1 && procedureText}</i></div>}
        <div className='ps-details-block-wrapper'>
          <div className='ps-details-block'>
            <div><Header size='medium'>{result.Name}</Header></div>
            <div className='mobile-procedure-text'><i>{procedure}</i> {numProcedures > 1 && procedureText}</div>
            {distance}
            <div>{result.Address.DisplayAddress}</div>
            <div><a onClick={openChat} style={{ cursor: 'pointer' }}>Chat</a> or {phoneSteerage} <a href={phoneLink}>{phoneFormatter(phoneNumber)}</a></div>
            {result.Fastpass ? <div>Must Present your Quest/ZERO Member ID Card</div> : null}
            <div><a href={googleLink} target='_blank' rel='noopener noreferrer'><Icon name='external' /> Open in Google Maps</a></div>
          </div>
        </div>
      </div>
    </div>)
  }
}

export default class ProviderSearchResults extends Component {
  renderNoResults (text) {
    return (<Container fluid text textAlign='center' className='ps-no-results'>{text}</Container>)
  }

  renderResults () {
    if (this.props.error) return this.renderNoResults('There was an error during your search. Please try again or contact us at support@zero.health')
    if (this.props.results) {
      if (this.props.results.length > 0) {
        return this.props.results.map(result => {
          let hovered = false
          if (result.ID === this.props.hovered) hovered = true
          return (<ProviderSearchResult onHover={this.props.onHover} id={result.ID} hovered={hovered} key={result.ID} result={result} />)
        })
      } else {
        return this.renderNoResults('No results found. Try adjusting your search criteria.')
      }
    }

    return this.renderNoResults('Search for a procedure or medical facility.')
  }

  render () {
    const isMainWebsite = this.props.params && this.props.params.source === 'main-website'

    // Adjust the container class if this is on an iframe on the main website
    const containerClassName = isMainWebsite
      ? 'provider-search-results-container-main-website-view'
      : 'provider-search-results-container'

    const searchResultsColumnClassName = isMainWebsite
      ? 'provider-search-results-column-main-website'
      : 'provider-search-results-column'

    const mapResultsColumnClassName = isMainWebsite
      ? 'provider-search-results-map-column-main-website'
      : 'provider-search-results-map-column'

    return (<div>
      <Container className={containerClassName}>
        <Grid columns={2} relaxed>
          <Grid.Column className={searchResultsColumnClassName} mobile={16} tablet={16} computer={8}>
            {this.props.searching ? <Dimmer active inverted className='ps-loader'><Loader /></Dimmer> : this.renderResults()}
          </Grid.Column>
          <Grid.Column className={mapResultsColumnClassName} computer={8} only='tablet computer'>
            <MapResultsContainer params={this.props.params} hovered={this.props.hovered} onHover={this.props.onHover} results={this.props.results} />
          </Grid.Column>
        </Grid>
      </Container>
    </div>)
  }
}

ProviderSearchResults.propTypes = {
  onHover: PropTypes.func.isRequired,
  results: PropTypes.array,
  hovered: PropTypes.number,
  searching: PropTypes.bool,
  error: PropTypes.bool
}
