import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon, Popup } from 'semantic-ui-react'
import GoogleMapReact from 'google-map-react'
import './MapResultsContainer.css'
import { getBoundsForMarkers, getCenterPoint, getDefaultLocation } from './helpers'
import { fitBounds } from 'google-map-react/utils'

const DEFAULT_ZOOM = 11

class MapResult extends Component {
  render () {
    const { text, hovered } = this.props
    const size = hovered ? 'huge' : 'big'
    const styleName = `marker-${size}`

    return (<div>
      <Popup
        trigger={<Icon name='point' size={size} className={styleName} />}
        content={text} />
    </div>)
  }
}

class MapResultsContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loadingGoogle: true
    }

    this.getCenterAndZoom = this.getCenterAndZoom.bind(this)
    this.handleHover = this.handleHover.bind(this)
    this.handleOffHover = this.handleOffHover.bind(this)
  }

  renderMapResults () {
    if (this.props.results && this.props.results.length > 0) {
      return this.props.results.map(result => {
        const hovered = this.props.hovered === result.ID
        return (<MapResult key={result.ID} hovered={hovered} id={result.ID} lat={result.Lat} lng={result.Lon} text={result.Name} />)
      })
    }
  }

  getCenterAndZoom () {
    const { results, location } = this.props
    let center, zoom

    if (results && results.length > 0) {
      if (results.length > 1) {
        // if we have multiple results, use the bounding algorithm
        const bounds = fitBounds(getBoundsForMarkers(results), { height: 800, width: 450 })
        center = bounds.center
        zoom = bounds.zoom
      } else {
        // if we have a single result, avoid the bounding algorithm for speed and because it is buggy
        let result = results[0]
        center = { lat: result.Lat, lng: result.Lon }
        zoom = DEFAULT_ZOOM
      }
    } else if (location.lat && location.lon) {
      let centerPoint = getCenterPoint(location)
      center = { lat: centerPoint.lat(), lng: centerPoint.lng() }
      zoom = DEFAULT_ZOOM
    } else {
      const bounds = fitBounds(getDefaultLocation(), { height: 800, width: 450 })
      center = bounds.center
      zoom = bounds.zoom
    }

    return { center, zoom }
  }

  handleHover (e, data) {
    this.props.onHover({ hovered: data.id })
  }

  handleOffHover () {
    this.props.onHover({ hovered: -1 })
  }

  render () {
    const { center, zoom } = this.getCenterAndZoom()
    const apiKey = { apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || null }

    // Adjust CSS class if the user is viewing in an iframe on the main site
    const divClassName = (this.props.params && this.props.params.source === 'main-website')
      ? 'maps-container-main-website'
      : 'maps-container'

    return (<div className={divClassName}>
      <GoogleMapReact
        {...apiKey}
        className='maps-container'
        onChildMouseEnter={this.handleHover}
        onChildMouseLeave={this.handleOffHover}
        zoom={zoom}
        center={center}
        margin={[30, 30, 30, 30]}
        onGoogleApiLoaded={({map, maps}) => this.setState({ loadingGoogle: false })}
        hoverDistance={30}>
        {this.renderMapResults()}
      </GoogleMapReact></div>)
  }
}

function mapStateToProps (state) {
  return {
    location: state.location
  }
}

export default connect(mapStateToProps, null)(MapResultsContainer)

MapResultsContainer.propTypes = {
  results: PropTypes.array,
  hovered: PropTypes.number,
  onHover: PropTypes.func.isRequired
}

MapResultsContainer.defaultProps = {
  center: { lat: 39.8283, lng: -98.5795 },
  zoom: 11
}
