import { SET_USER } from '../actions/UserActions'

export default function (state = null, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        info: {...action.user}
      }
    default:
      return {
        ...state
      }
  }
}
