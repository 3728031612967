import React from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Button } from 'semantic-ui-react'
import { ZipcodeInput } from '../../components/Inputs/Zipcode'
import queryString from 'query-string'
// import CategorySearchInput from '../../components/CategorySearchInput'
import { isValidZipcode } from '../../helpers'
import BaseComponent from '../../components/BaseComponent'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import './ProviderSearch.css'
import _ from 'lodash'
const CURRENT_LOCATION = 'Current Location'

class ProviderSearchBar extends BaseComponent {
  constructor (props) {
    super(props)
    let zip = props.params.zipcode || ''

    this.zipRef = React.createRef() // create a ref to focus the zipcode input when needed.
    this.state = {
      lat: props.params.lat || null,
      lon: props.params.lon || null,
      fields: {
        distance: props.params.distance || '25mi',
        query: props.params.query || '',
        zipcode: zip,
        zipcodeVal: zip,
      },
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDistanceChange = this.handleDistanceChange.bind(this)
    this.ableToSearch = this.ableToSearch.bind(this)
    this.checkToAutoExecSearch = this.checkToAutoExecSearch.bind(this)
    this.executeSearch = this.executeSearch.bind(this)
    this.handleIncompleteSearch = this.handleIncompleteSearch
  }

  executeSearch (params, lat, lon) {
    const { zipcodeVal, ...filteredParams } = params
    const { user } = this.props
    const qsParams = _.assign(filteredParams, { lat, lon })
    if (lat !== null && lon !== null) delete qsParams.zipcode
    if(!!user.employerIDs && user.employerIDs.length) {
      qsParams._encoded = btoa(JSON.stringify({employerIDs: user.employerIDs}))
    }

    this.props.history.push(`/search?${queryString.stringify(qsParams)}`)
    delete qsParams.userinfo
    this.props.search({
      ...qsParams,
      lat: lat || null,
      lon: lon || null
    })
  }

  handleSearch (e) {
    this.checkShowMeTheMoney(this.state.fields)

    if (this.checkToAutoExecSearch()) {
      this.refs.searchBtn.focus() // this makes sure we clear focus on inputs when a search is exec.
      return
    }

    this.handleIncompleteSearch()
  }

  handleClearLocation = (e) => {
    this.props.clearLocation()
    this.setState({
      lat: null,
      lon: null,
      fields: {
        ...this.state.fields,
        zipcode: '',
        zipcodeVal: ''
      }
    })
  }

  handleLocationRequest = (e) => {
    if (navigator && navigator.geolocation) {
      this.props.getLocation()
    }
  }

  handleIncompleteSearch () {
    const { query, zipcode } = this.state.fields

    if (!query) {
      this.refs.query.focus()
      return
    }

    if (!isValidZipcode(zipcode)) {
      this.zipRef.current.focus()
    }
  }

  buildDistanceSearchOptions () {
    return [
      { key: '25mi', text: '25 mi', value: '25mi' },
      { key: '50mi', text: '50 mi', value: '50mi' },
      { key: '100mi', text: '100 mi', value: '100mi' },
      { key: '300mi', text: '300 mi', value: '300mi' }
    ]
  }

  componentWillMount () {
    this.checkToAutoExecSearch()
    this.setState({
      distanceOptions: this.buildDistanceSearchOptions()
    })
  }

  checkToAutoExecSearch (params) {
    const { lat, lon } = this.state
    const searchParams = _.assign({}, params, this.props.params, this.state.fields)

    if (this.ableToSearch()) {
      this.executeSearch(searchParams, lat, lon)
      return true
    }

    return false
  }

  ableToSearch () {
    const { lat, lon } = this.state
    const { query, zipcode } = this.state.fields

    if (query) {
      if (isValidZipcode(zipcode) || (lat && lon)) {
        return true
      }
    }

    return false
  }

  isValidZipcode (zipcode) {
    if (zipcode && zipcode.length === 5) return true
    return false
  }

  componentWillReceiveProps (nextProps) {
    const { location } = nextProps.curLocation
    if (location && location.lat && location.lon && location.lat !== this.state.lat && location.lon !== this.state.lon) {
      this.setState({
        ...this.state,
        lat: location.lat,
        lon: location.lon,
        fields: {
          ...this.state.fields,
          zipcode: null,
          zipcodeVal: CURRENT_LOCATION
        }
      })
      if (this.state.fields.query) {
        this.executeSearch(this.state.fields, location.lat, location.lon)
      }
    }
  }

  handleDistanceChange (e, data) {
    const { autoExec } = this.props
    this.handleSelectChange(e, data)

    autoExec && this.checkToAutoExecSearch({ distance: data.value })
  }

  // special case to handle "Show me the money!"
  checkShowMeTheMoney (params) {
    let { query } = params
    if (query !== undefined && query !== null && typeof query === 'string') {
      query = query.toLowerCase()

      if (query.indexOf('show me the') >= 0) {
        window.open(process.env.REACT_APP_SHOW_ME_THE_MONEY_URL)
      }
    }
  }

  handleZipChange = (val) => {
    this.setState({
      fields: {
        ...this.state.fields,
        zipcode: val,
        zipcodeVal: val
      }
    })
  }

  renderLocation () {
    const loading = this.props.curLocation.isLoading

    return (<ZipcodeInput loading={loading} name='zipcode' value={this.state.fields.zipcodeVal} onChange={this.handleZipChange} onLocationRequest={this.handleLocationRequest} onClearLocation={this.handleClearLocation} focusRef={this.zipRef} lat={this.state.lat} lon={this.state.lon} />)
  }

  render () {
    const providers = (<Icon name='doctor' />)

    const searchPlaceholder = 'MRI, Knee Surgery, etc...'

    // AutoSuggest for when we want it
    // <CategorySearchInput source={this.props.searchSource} placeholder={searchPlaceholder} onChange={this.handleInputChange} name='query' className='searchbar-field field' />

    return (
      <Form size='large'>
        <Form.Group>
          {this.renderInput('query', 'Find', searchPlaceholder, { icon: providers, iconPosition: 'left' })}
          { this.renderLocation() }
          <Form.Select className='searchbar-field distance' name='distance' label='Distance' value={this.state.fields.distance} onChange={this.handleDistanceChange} options={this.state.distanceOptions} />
          <Form.Field className='searchbar-btn'>
            <Button size='large' onClick={this.handleSearch} type='submit' ref='searchBtn'><Icon name='search' />Search</Button>
          </Form.Field>
        </Form.Group>
      </Form>)
  }
}

function mapStateToProps (state) {
  return {
    user: state.user.info || {}
  }
}

export default compose(withRouter, connect(mapStateToProps))(ProviderSearchBar)

ProviderSearchBar.defaultProps = {
  curLocation: {},
  autoExec: true
}

ProviderSearchBar.propTypes = {
  curLocation: PropTypes.object,
  search: PropTypes.func.isRequired,
  getLocation: PropTypes.func.isRequired,
  clearLocation: PropTypes.func.isRequired,
  params: PropTypes.object,
  autoExec: PropTypes.bool
}
