import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ProviderSearchBar from './ProviderSearch/ProviderSearchBar'
import NearbyProvidersSearchBar from './ProviderListing/NearbyProvidersSearchBar'
import './Landing.scss'
import { Container, Header, Divider } from 'semantic-ui-react'
import { setUser } from '../actions/UserActions'
import Logo from '../assets/logo-color.png'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

class Landing extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: props.params.userinfo
    }
  }

  componentDidMount() {
    const {userInfo} = this.state
    if (userInfo) this.props.setUser(JSON.parse(atob(userInfo)))
  }

  render () {
    const { user } = this.props
    let containerClassname = 'search-container'
    let logoComponent = null
    // Check to see if we are on the main website or myzero based on the URL
    if (this.props.params && this.props.params.source && this.props.params.source === "main-website") {
      // Set container class name to the one specfic to the main website
      containerClassname = 'search-container-main-website'
      logoComponent = (
        <div id="logo-container" style={{textAlign: 'center', width: '100%' }}>
          <img alt="logo" id="logo-image" src={Logo} />
        </div>
      )
    }

    return (<Container className={containerClassname}>
      {logoComponent}
      <div>
        <div>
          {user.name ? <Header style={{fontFamily: 'Raleway'}} size='huge'>Welcome {user.name}</Header> : null}
          <Header style={{fontFamily: 'Raleway'}} size='huge'>Search for procedures, specialities and facilities</Header>
          <ProviderSearchBar style={{fontFamily: 'Raleway !important'}} params={this.props.params} autoExec={false} loading={this.props.searching} search={this.props.search} history={this.props.history} getLocation={this.props.getLocation} clearLocation={this.props.clearLocation} curLocation={this.props.curLocation} />
        </div>
        <Divider />
        <div id="bottom-search-section">
          <Header style={{fontFamily: 'Raleway'}}  size='huge'>See a complete listing of Providers in your area</Header>
          <NearbyProvidersSearchBar style={{fontFamily: 'Raleway !important'}} params={this.props.params} loading={this.props.searching} findNearby={this.props.findNearby} history={this.props.history} autoExec={false} />
        </div>
      </div>
    </Container>)
  }
}

function mapStateToProps (state) {
  return {
    user: state.user.info || {}
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ setUser }, dispatch)
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Landing)

Landing.propTypes = {
  getLocation: PropTypes.func.isRequired,
  findNearby: PropTypes.func.isRequired,
  clearLocation: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  curLocation: PropTypes.object,
  error: PropTypes.bool,
  params: PropTypes.object,
  history: PropTypes.object
}
