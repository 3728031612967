// dirty function, but should go in as PR for google-react-maps
export function getBoundsForMarkers (markers, location) {
  const google = window.google || {}

  if (markers && markers.length > 0 && google !== undefined && google.maps !== undefined) {
    const bounds = new google.maps.LatLngBounds()

    markers.forEach(marker => {
      bounds.extend(new google.maps.LatLng({lat: marker.Lat, lng: marker.Lon}))
    })

    return getBounds(bounds)
  }

  return getDefaultLocation(location)
}

export function getCenterPoint (location) {
  if (location && location.lat && location.lon) {
    const google = window.google || {}
    return new google.maps.LatLng({lat: location.lat, lng: location.lon})
  }
}

export function getDefaultLocation () {
  return {
    nw: {
      lat: 50.01038826014866,
      lng: -118.6525866875
    },
    se: {
      lat: 32.698335045970396,
      lng: -92.0217273125
    }
  }
}

function getBounds (bounds) {
  const ne = bounds.getNorthEast()
  const sw = bounds.getSouthWest()

  return { ne: { lat: ne.lat(), lng: ne.lng() }, sw: { lat: sw.lat(), lng: sw.lng() } }
}
