import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryWrapper } from '../ErrorBoundary'
import ReduxThunk from 'redux-thunk'
import reducers from './reducers'
import InboxContainer from './containers/InboxContainer'

const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore)

export default class Inbox extends Component {
  render () {
    return (<div>
      <ErrorBoundary FallbackComponent={ErrorBoundaryWrapper}>
        <Provider store={createStoreWithMiddleware(reducers)}>
          <Router>
            <Switch>
              <Route exact path='/' render={(props) => <InboxContainer {...props} />} />
            </Switch>
          </Router>
        </Provider>
      </ErrorBoundary>
    </div>)
  }
}
