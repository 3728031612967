import _ from 'lodash'
const GOOGLE_MAPS_URL = process.env.REACT_APP_GOOGLE_MAPS_URL

function isValidZipcode (zipcode) {
  return (zipcode && isInteger(zipcode) && zipcode.toString().length === 5)
}

function distanceFormatter (cell, row) {
  const value = _.toNumber(cell).toFixed(1)
  return `${value} mi`
}

function isInteger (value) {
  let parsed = _.toNumber(value)
  return parsed === _.toInteger(value)
}

function isFloat (value) {
  let parsed = parseFloat(value)
  return parsed === _.toNumber(value)
}

function phoneFormatter (cell, row) {
  const value = _.toString(cell)
  const areaCode = value.substr(0, 3)
  const firstPart = value.substr(3, 3)
  const secondPart = value.substr(6, 4)

  return `(${areaCode})-${firstPart}-${secondPart}`
}

function priceFormatter (cell, row) {
  const value = _.toNumber(cell).toFixed(2)

  return `$${value}`
}

function generateGoogleMapsLink (address) {
  const formatted = encodeURI(address)

  return `${GOOGLE_MAPS_URL}&query=${formatted}`
}

export { generateGoogleMapsLink, phoneFormatter, priceFormatter, isFloat, isInteger, distanceFormatter, isValidZipcode }
