import React from 'react'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google'
import './Captcha.css'

// this is the google captcha v2. Manage it in https://www.google.com/recaptcha/admin
// keys in lastpass under google recaptcha.
export class Captcha extends React.Component {
  state = {
    loaded: false
  }

  componentDidMount () {
    loadReCaptcha()
    this.checkIfLoaded()
  }

  checkIfLoaded = () => {
    const intervalId = setInterval(() => {
      const isLoaded = window.grecaptcha && window.grecaptcha.render !== undefined
      if (isLoaded) {
        this.setState({ loaded: true })
        clearInterval(intervalId)
      }
    })
  }

  onSuccess = () => {
    console.log('success')
  }

  verifyCallback = (token) => {
    this.props.onUserVerified(token)
  }

  // return (<div class='g-recaptcha confirm-captcha' data-sitekey='6Lf1b4oUAAAAANlQBs0cWJ00oi6qcJFi_V_YB7Eb' />)
  render () {
    const captchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_V2 || 'nokey'
    const { loaded } = this.state

    return (<div className='confirm-captcha'>
      { loaded ? <ReCaptcha
        size='normal'
        render='explicit'
        sitekey={captchaKey}
        onSuccess={this.onSuccess}
        verifyCallback={this.verifyCallback} /> : <div /> }
    </div>)
  }
}
