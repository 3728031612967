import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash' // this is a bit heavy handed
import { Form, Icon, Input } from 'semantic-ui-react'

export const ZipcodeInput = (props) => {
  const handleZipInputChange = (e) => {
    const rawVal = e.target.value.substr(0, 5)
    let zip = ''
    if (rawVal && rawVal.length > 0) {
      const parts = []
      _.forEach(rawVal, digit => {
        const parsedDigit = _.parseInt(digit, 10)
        if (parsedDigit !== '' && !isNaN(parsedDigit)) {
          parts.push(parsedDigit)
        }
      })
      zip = parts.join('')
    }

    props.onChange(zip)
    e.preventDefault()

    return null
  }

  const { loading, name, value } = props
  const renderLocation = props.onLocationRequest !== undefined
  let handleIconClick = props.onLocationRequest
  let iconName = 'location arrow'
  let handleChange = loading ? nullHandler : handleZipInputChange

  if (props.lat && props.lon) {
    iconName = 'close'
    handleIconClick = props.onClearLocation
    handleChange = nullHandler
  }

  const icon = renderLocation ? <Icon name={iconName} onClick={handleIconClick} link /> : null

  function nullHandler () {
    return null
  }

  return (<Form.Field style={{fontFamily: 'Raleway !important'}}  className='searchbar-field zipcode'>
    <label>Zip</label>
    <Input style={{fontFamily: 'Raleway !important'}}  loading={loading} icon={icon} name={name} value={value} onChange={handleChange} ref={props.focusRef} placeholder={props.placeholder} />
  </Form.Field>)
}

ZipcodeInput.defaultProps = {
  placeholder: 'Ex. 74120'
}

ZipcodeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onLocationRequest: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  focusRef: PropTypes.object,
  loading: PropTypes.bool
}
