import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL
const axiosClient = axios.create({
  baseUrl: BASE_URL
})

// TODO: this needs to be updated
axiosClient.interceptors.response.use(function (response) {
  return apiResponse(response)
}, function (error) {
  if (error && error.response && error.response.status === 401) {
    window.location.assign('/')
    return
  }

  console.error('Detailed error for debugging: ', error.response.data)

  return {
    ...error.response.data,
    error: true
  }
})

function apiResponse (res) {
  const { data, ...resNoData } = res
  return {
    Data: data.Data || data || null,
    Meta: data.Meta || null,
    ...resNoData
  }
}

function queryParams (params) {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&')
}

/* new methods: use these - see actions/index.js */

export function get (url, params) {
  let requestUrl = BASE_URL + url

  if (params) { requestUrl += '?' + queryParams(params) }

  return axiosClient.get(requestUrl, getBaseConfig())
}

export function patch (url, body) {
  const requestUrl = BASE_URL + url

  return axiosClient.patch(requestUrl, body, getBaseConfig())
}

export function post (url, body) {
  const requestUrl = BASE_URL + url

  return axiosClient.post(requestUrl, body, getBaseConfig())
}

export function put (url, body) {
  const requestUrl = BASE_URL + url

  return axiosClient.put(requestUrl, body, getBaseConfig())
}

export function del (url) {
  const requestUrl = BASE_URL + url

  return axiosClient.delete(requestUrl, getBaseConfig())
}

function getBaseConfig () {
  return {
    headers: getHeaders()
  }
}

function getHeaders () {
  return {
    Accept: 'application/json'
  }
}

export var url = BASE_URL
