import React from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Progress } from 'semantic-ui-react'
import ReactS3Uploader from 'react-s3-uploader'

export class FileUploader extends React.Component {
  constructor (props) {
    super(props)

    this.initialState = {
      uploadProgress: '0',
      file: {}
    }

    this.state = this.initialState
  }

  componentDidMount () {
    this.setState(this.initialState)
  }

  onUploadProgress = (e) => {
    const { uploadProgress } = this.state
    if (uploadProgress === '0') this.props.onUploadStart()
    this.setState({ uploadProgress: e })
  }

  onUploadComplete = (e) => {
    // yeah yeah not a one way data flow, but PITA otherwise.
    this.props.onUploadComplete()
  }

  onUploadError = (e) => {
    this.setState({ error: true })
    this.props.onUploadError()
  }

  onFileSelected = (file, next) => {
    this.setState({ fileExistsError: null,
      error: false,
      file: {
        ...this.state.file,
        S3Key: file.name,
        Size: file.size
      }
    })
    if (this.props.onFileSelected) {
      this.props.onFileSelected(file)
    }
    next(file)
  }

  render () {
    const { getPresignedUploadUrl } = this.props
    const { uploadProgress, file, error } = this.state

    return (<div>
      <Container textAlign='center'>
        <ReactS3Uploader
          ref={(uploader) => { this._uploader = uploader }}
          server={process.env.REACT_APP_BASE_URL}
          getSignedUrl={getPresignedUploadUrl}
          uploadRequestHeaders={{}}
          onProgress={this.onUploadProgress}
          onError={this.onUploadError}
          onFinish={this.onUploadComplete}
          preprocess={this.onFileSelected}
          name='fileUpload'
          id='fileUpload'
          style={{ display: 'none' }} />
        <label htmlFor='fileUpload'>
          { file.S3Key ? (<Button as='span' animated='fade'>
            <Button.Content hidden>Change file</Button.Content>
            <Button.Content visible>{file.S3Key}</Button.Content>
          </Button>) : <Button as='span'>Choose a file</Button>}
        </label>
        <br />
        { uploadProgress !== '0' && <div>
          <Progress className='field upload-bar' percent={uploadProgress} progress='percent' active={!error && uploadProgress !== 100} error={error} success={uploadProgress === 100} />
        </div> }
      </Container>
    </div>)
  }
}

FileUploader.propTypes = {
  onUploadComplete: PropTypes.func,
  onUploadStart: PropTypes.func,
  onUploadError: PropTypes.func,
  onFileSelected: PropTypes.func,
  getPresignedUploadUrl: PropTypes.func.isRequired
}
