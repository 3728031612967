import { SUBMITTING_UPLOAD, SUBMIT_UPLOAD_SUCCESS, SUBMIT_UPLOAD_ERROR, GETTING_PRESIGNED_UPLOAD_URL, GET_PRESIGNED_UPLOAD_URL_SUCCESS, GET_PRESIGNED_UPLOAD_URL_ERROR } from '../actions'

export default function (state = null, action) {
  switch (action.type) {
    case GETTING_PRESIGNED_UPLOAD_URL:
      return {
        ...state,
        result: null,
        error: false
      }
    case GET_PRESIGNED_UPLOAD_URL_SUCCESS:
      console.log(action, 'success')
      return {
        ...state,
        result: action.payload,
        error: false
      }
    case GET_PRESIGNED_UPLOAD_URL_ERROR:
      console.log(action, 'error')
      return {
        ...state,
        result: null,
        error: true
      }
    case SUBMITTING_UPLOAD:
      return {
        ...state,
        submitting: true,
        uploadResult: null,
        error: false
      }
    case SUBMIT_UPLOAD_SUCCESS:
      console.log(action, 'success')
      return {
        ...state,
        submitting: false,
        uploadResult: action.payload,
        error: false
      }
    case SUBMIT_UPLOAD_ERROR:
      console.log(action, 'error')
      return {
        ...state,
        submitting: false,
        uploadResult: null,
        error: true
      }
    default:
      return {
        ...state
      }
  }
}
